.fc-event{
  cursor: pointer;
}

.z-12k {
  z-index: 12000;
}

.ff-serif {
  font-family: serif;
}

#obligationsPdfExport {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
}


.borderBetweenChildren {
  overflow: hidden;
}
.borderBetweenChildren > * {
  position: relative;
}
.borderBetweenChildren > *:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  display: inline-block;
  width: 2px;
  height: 90%;
  background-color: $default;
  transform: translateY(-50%);
  margin-right: 1px;
}